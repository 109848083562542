import React, { useMemo, useRef } from "react";
import { GlobalHeader, GlobalFooter } from "@/components/Layouts";
import { useConfigProvider } from "@/context/ConfigProvider";
import classNames from "classnames";
// import "./index.less";
import { AppSettings } from "@/shared/app-common";
import { useTranslation } from "next-i18next";
import { EventName } from "@/shared/event-types";
import { useRouter } from "next/router";
import { imgUrl } from "@/shared/utils";
import { useQuery } from "@tanstack/react-query";
import Head from "next/head";

interface LayoutProps {
  head?: React.ReactNode;
  children: React.ReactNode;
  showHeader?: boolean;
  showFooter?: boolean;
}

interface TdkData {
  title: string;
  description: string;
  keywords: string;
}

const Layout = ({ children, head, showHeader = true }: LayoutProps) => {
  const router = useRouter();
  const { isMobile } = useConfigProvider();
  const { t } = useTranslation("common");
  const ref = useRef(null);

  const getNavItems = () => {
    switch (AppSettings.type) {
      case "16":
        return [
          {
            text: t("Solution"),
            path: `/onlyfans-protection`,
            gaName: EventName.header_service,
            dropdownItems: [
              {
                text: t("OnlyFans Content Protection"),
                path: `/onlyfans-protection`,
                gaName: EventName.header_onlyfans_protection,
              },
              {
                text: t("Impersonation Account Removal"),
                path: `/remove-fake-account`,
                gaName: EventName.header_remove_fake_account,
              },
              {
                text: t("Cam Model Protection"),
                path: `/cam-model-protection`,
                gaName: EventName.header_cam_model_protection,
              },
            ],
          },
          {
            text: t("Service"),
            path: `/content-monitoring`,
            gaName: EventName.header_service,
            dropdownItems: [
              {
                text: t("Content Monitoring"),
                path: `/content-monitoring`,
                gaName: EventName.header_onlyfans_protection,
              },
              {
                text: t("DMCA Takedown"),
                path: `/dmca-takedown`,
                gaName: EventName.header_remove_fake_account,
              },
            ],
          },
          {
            text: t("Pricing"),
            path: "/plan",
            gaName: EventName.header_Plan,
          },
          {
            text: t("Resources"),
            path: `/dmca-protection-badge`,
            gaName: EventName.header_tool,
            dropdownItems: [
              {
                text: t("Blog"),
                path: `/blog`,
                gaName: EventName.header_tool,
              },
              {
                text: t("DMCA Badges"),
                path: `/dmca-protection-badge`,
                gaName: EventName.header_tool,
              },
              {
                text: t("OnlyFans Restricted Words Checker"),
                path: `/onlyfans-restricted-words-checker`,
                gaName: EventName.header_tool,
              },
              {
                text: t("OnlyFans Caption Generator"),
                path: `/onlyfans-caption-generator`,
                gaName: EventName.header_tool,
              },
              {
                text: t("Platform Comparison"),
                path: `/compare`,
                gaName: EventName.header_tool,
              },
            ],
          },
        ];

      default:
        [{}];
    }
  };
  // 后台配置tdk
  const { data: tdkData } = useQuery<TdkData>({
    queryKey: ["tdk", router.pathname, router.locale],
    staleTime: 1000 * 60 * 5,
    refetchOnMount: false,
  });
  const tdkContent = useMemo(() => {
    try {
      if (Object.keys(tdkData).length === 0) return null;
    } catch (error) {
      console.log(error);
    }

    return (
      <Head>
        {tdkData?.title && <title key="title">{tdkData?.title}</title>}
        {tdkData?.description && (
          <meta
            key="description"
            name="description"
            content={tdkData?.description}
          />
        )}
        {tdkData?.keywords && (
          <meta key="keywords" name="keywords" content={tdkData?.keywords} />
        )}
      </Head>
    );
  }, [tdkData]);

  // 处理 head 中的 TDK，移除其中的 title 和 meta 标签
  const processedHead = useMemo(() => {
    try {
      if (!head || Object.keys(tdkData).length === 0) return head;
    } catch (error) {
      console.log(error);
    }

    // 如果 head 是 React 元素，需要处理其子元素
    const headElement = head as React.ReactElement;
    if (headElement.type === Head) {
      const children = React.Children.toArray(headElement.props.children);
      // 过滤掉 title 和 指定的 meta 标签
      const filteredChildren = children.filter((child) => {
        try {
          if (!React.isValidElement(child)) return true;
          if (child.type === "title" && tdkData.title) {
            return false;
          }
          if (child.type === "meta") {
            if (child.props.name === "description" && tdkData.description) {
              return false;
            }
            if (child.props.name === "keywords" && tdkData.keywords) {
              return false;
            }
          }
        } catch (error) {
          console.log(error);
        }
        return true;
      });

      return <Head>{filteredChildren}</Head>;
    }
    return head;
  }, [head, tdkData]);

  return (
    <div
      className={"layout"}
      style={{
        backgroundSize: "contain",
        backgroundImage: `url(${imgUrl(
          "https://res-front.pumpsoul.com/prod-erasa/image/background-icon.png",
          "big"
        )})`,
      }}
    >
      {processedHead}
      {tdkContent}
      <header className={"header"}>
        <GlobalHeader isMobile={isMobile} navItems={[...getNavItems()]} />
      </header>
      <main
        ref={ref}
        style={{
          marginTop: "calc(var(--pietra-global-header-heigth))",
        }}
        className={classNames("main", {
          mobile: isMobile,
        })}
      >
        <div>{children}</div>
      </main>
      <footer className={"footer"}>
        <GlobalFooter />
      </footer>
    </div>
  );
};
export default Layout;
