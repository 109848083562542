import Head from "next/head";
import type { GetServerSideProps, InferGetServerSidePropsType } from "next";

import { NextPageWithLayout } from "./_app";
import React, { useMemo } from "react";
import Layout from "../index-layouts/index";
import { AppSettings } from "@/shared/app-common";
import { serverSideTranslations } from "next-i18next/serverSideTranslations";
import dynamic from "next/dynamic";

const HomeContent = dynamic(() => import("@/components/Home"), {
  ssr: true,
  loading: () => null,
  suspense: false,
});

export const getServerSideProps: GetServerSideProps = async (context) => {
  context.res.setHeader(
    "Cache-Control",
    "public, s-maxage=10, stale-while-revalidate=59"
  );

  const { locale = "en" } = context;
  const localeRes = await serverSideTranslations(locale, ["common"]);
  let currentURL = `${process.env.NEXT_APP_WEB_HOST}`;
  if (locale != "en") {
    currentURL = `${process.env.NEXT_APP_WEB_HOST}/${locale}`;
  }
  const tempData =
    localeRes["_nextI18Next"]["initialI18nStore"][locale]["common"] ?? {};
  return {
    props: {
      ...localeRes,
      herf: currentURL,
      locale,
      // revalidate: 60,
      title: tempData?.head_Meta_Title ?? null,
      description: tempData?.head_Meta_Desc ?? null,
      keywords: tempData?.head_Meta_Keys ?? null,
    },
  };
};

const Home: NextPageWithLayout<
  InferGetServerSidePropsType<typeof getServerSideProps>
> = (props: any) => {
  return useMemo(() => <HomeContent data={props} />, [props]);
};
Home.getLayout = function getLayout(page: React.ReactElement) {
  const title = page?.props?.title;
  const desc = page?.props?.description;
  const keywords = page?.props?.keywords;

  return (
    <Layout
      head={
        <Head>
          <title>{title}</title>
          <meta name="title" content={title} />
          <meta name="description" content={desc} />
          <meta name="keywords" content={keywords} />

          <meta property="og:title" content={title} />
          <meta property="og:description" content={desc} />
          <meta
            property="og:image"
            content={"https://cdn.erasa.ai/image/dmca-5.png"}
          />
          <meta
            property="og:image:secure_url"
            content={"https://cdn.erasa.ai/image/dmca-5.png"}
          />
          <meta property="og:type" content="summary" />
          <meta property="og:url" content={page.props?.herf} />

          <meta name="twitter:title" content={title} />
          <meta name="twitter:description" content={desc} />
          <meta
            name="twitter:image"
            content={"https://cdn.erasa.ai/image/dmca-5.png"}
          />
          <meta name="twitter:card" content="summary" />
          <meta name="twitter:site" content={page.props?.herf} />

          <meta
            name="viewport"
            content="width=minimum-scale=1, initial-scale=1, width=device-width, shrink-to-fit=no, user-scalable=no, viewport-fit=cover"
          />
          <link
            rel="alternate"
            hrefLang="en"
            href={`${process.env.NEXT_APP_WEB_HOST}/en`}
          />
          <link
            rel="alternate"
            hrefLang="zh"
            href={`${process.env.NEXT_APP_WEB_HOST}/zh`}
          />
          <link
            rel="alternate"
            hrefLang="tw"
            href={`${process.env.NEXT_APP_WEB_HOST}/tw`}
          />
          <link
            rel="alternate"
            hrefLang="x-default"
            href={`${process.env.NEXT_APP_WEB_HOST}`}
          />

          <link rel="canonical" href={page.props?.herf} />
          <link rel="icon" href={AppSettings.icoPath} />
        </Head>
      }
    >
      {page}
    </Layout>
  );
};

export default Home;
